@import './styling/mixins.scss';
body {
	margin: 0;
	padding: 0 !important;
	overflow: auto !important;
	font-family: 'Inter', sans-serif;
	// font-family: 'Roboto', sans-serif;
}

html {
	scroll-behavior: smooth;
}

* {
	box-sizing: border-box;
	::-webkit-scrollbar {
		width: 20px;
	}

	::-webkit-scrollbar-track {
		background-color: transparent;
	}

	::-webkit-scrollbar-thumb {
		background-color: #d6dee1;
		border-radius: 20px;
		border: 6px solid transparent;
		background-clip: content-box;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: #a8bbbf;
	}
}

:root {
	--primary-color: #1378a5;
	--border-radius: 5px;
	--base-page-padding: 20px;
	--grey: #818181;
	--box-shadow: 0px 4px 10px 0px #0000001a;
}

.blue_link {
	color: #1378a5;
	text-decoration: none;
	cursor: pointer;
}

.modinputs {
	@include flex();
	width: 100%;
	justify-content: space-between;
	& > * {
		margin-right: 10px;
		margin-top: 0;
	}

	input {
		width: 100%;
	}
}

table {
	border-collapse: collapse;
}

.itemholder {
	@include flex();
	flex-wrap: wrap;
	gap: 15px 33px;
	margin-top: 20px;
	cursor: pointer;
	.item {
		box-shadow: 0px 4px 10px 0px #0000001a;
		background: #ffffff;
		padding: 20px;
		width: 31%;
		font-weight: 500;

		p:first-child {
			margin-bottom: unset;
		}
		p:last-child {
			margin-top: 5px;
		}
	}
}

textarea {
	font-family: inherit;
	font-size: inherit;
}

input::-webkit-input-placeholder {
	font-family: inherit;
}

input:-ms-input-placeholder {
	font-family: inherit;
}

input:-moz-placeholder {
	font-family: inherit;
}

input::-moz-placeholder {
	font-family: inherit;
}

a:hover {
	text-decoration: none !important;
}

@media only screen and (max-width: 310px) {
	html {
		overflow: overlay;
	}
}

.iconer {
	& path {
		stroke: inherit;
	}
}
