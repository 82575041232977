@import '../../styling/mixins.scss';
.navbar-mod {
	justify-content: space-between !important;
	svg {
		padding: unset !important;
	}
}
.navbar > * {
	&:first-child {
		padding: 5px !important;
	}
}
.logo-mod {
	width: 22.7% !important;
	box-sizing: content-box !important;
	background-color: var(--primary-color) !important;
	color: white !important;
	text-align: center !important;
	padding-right: 0 !important;
	padding-left: 0 !important;
	height: var(--navheight);
	padding: 5px 0;
}

.nvb_target {
	margin: unset;
	color: var(--primary-color);
	border-bottom: 2px solid transparent;

	&:hover {
		border-bottom: 2px solid var(--primary-color);
		cursor: pointer;
	}
}

.sideModal {
	&_overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		// background-color: red;
		z-index: 1000;
		cursor: pointer;
	}
	&_content {
		position: fixed;
		animation: sideM 0.3s ease-in 1 normal;

		top: var(--navheight);
		right: 0%;
		height: calc(100vh - var(--navheight));
		z-index: 1000;
		width: 25%;
		background-color: white;
		box-shadow: -4px 4px 10px 0px #0000001a;
	}

	&__main {
		padding: 10px;
	}

	&_graph {
		@include flex();
		gap: 10px;
		justify-content: center;
		& > .pie-chart {
			width: 30%;
		}
		& > div {
			& > p {
				margin-left: 20px;
				position: relative;
				font-size: 14px;
				&::before {
					content: '';
					width: 7px;
					height: 7px;
					border-radius: 50%;
					position: absolute;
					top: 5px;
					left: -20px;
					z-index: 1000;
				}
				&:first-child {
					&::before {
						background-color: #2d8cff;
					}
				}
				&:nth-child(2) {
					&::before {
						background-color: #fbbc05;
					}
				}
				&:last-child {
					&::before {
						background-color: #eb3f5e;
					}
				}
			}
		}
	}

	&_div {
		background-color: #f9f9fb;
		border-radius: 5px;
		padding: 5px 20px;
		box-shadow: -4px 4px 10px 0px #0000001a;
		margin: 10px;
		& > div {
			margin-bottom: 5px;
			padding-bottom: 5px;
			p {
				margin: 0;
				font-weight: 700;
				font-size: 18px;
			}
			span {
				font-weight: 300;
				font-size: 14px;
				text-align: center;
			}
			&:first-child {
				@include flex(column);
				align-items: center;
				border-bottom: 1px solid grey;
			}
			&:last-child {
				@include flex();
				align-items: flex-start;
				gap: 10px;
				& > * {
					flex: 1;
				}
				& > div {
					@include flex(column);
					justify-content: flex-start;
					gap: 10px;
					position: relative;
					margin-top: 20px;
					p {
						font-size: 16px;
						text-align: center;
					}
					&::before {
						content: '';
						width: 7px;
						height: 7px;
						border-radius: 50%;
						position: absolute;
						top: -20%;
						left: 50% !important;
						transform: translate(-50%, -50%);
						left: -20px;
						z-index: 1000;
					}
					&:first-child {
						&::before {
							background-color: #2d8cff;
						}
					}
					&:nth-child(2) {
						&::before {
							background-color: #fbbc05;
						}
					}
					&:last-child {
						&::before {
							background-color: #eb3f5e;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	.logo-mod {
		// width: 30%;
		background-color: white !important;
	}
}
@media only screen and (max-width: 1000px) {
	.logo-mod {
		margin-left: 50px;
	}
}
