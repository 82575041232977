.footer {
  background: white;
}

.footer_wrapper {
  // height: 750px;
  max-width: 1440px;
  margin: 0 auto;

  hr {
    border: 1px solid #c4c4c4;
    margin-bottom: 30px;
  }
}

.footer_links {
  display: flex;
  justify-content: space-evenly;

  ul {
    display: flex;
    flex-direction: column;
  }

  h3 {
    font-weight: 700;
    // font-size: 24px;
    line-height: 29px;
  }

  .footer_links_wrapper {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 400;
    // font-size: 20px;
    line-height: 50px;
  }
}

.footer_details {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-around;

  .useful_links {
    display: flex;
    justify-content: space-around;
    font-style: normal;
    font-weight: 400;
    // font-size: 18px;
    line-height: 22px;
    color: #818181;
    width: 800px;
  }

  .social_media_links {
    width: 200px;
    display: flex;
    justify-content: space-evenly;
  }
}

.footer_desc {
  display: flex;
  width: 800px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;

  .bpo_homes_logo {
    color: var(--primary-color);
    font-size: 32px;
    font-weight: 900;
    margin: 0;
  }

  p {
    font-weight: 400;
    // font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #818181;
    padding: 10px 0;
  }

  .bpo_logo:hover {
    cursor: pointer;
  }
}

.footer_info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  p {
    font-weight: 400;
    // font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding: 0 10px;

    color: #818181;
  }
}

@media (max-width: 900px) {
  .footer {
    display: flex;
    align-items: center;
    width: 100%;

    .footer_wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;

      .footer_links {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          p {
            text-align: center;
          }
        }
      }

      .footer_links_wrapper {
        display: none;
      }
    }

    .footer_details {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;

      .links {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .useful_links {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
        }
      }
    }

    .footer_desc {
      display: flex;
      flex-direction: column;
      width: 100%;

      .footer_text {
        padding: 0 10px;
      }

      .footer_info {
        display: none;
      }

      .footer_description {
        display: none;
      }
    }

    .footer_info {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin: 0;
      }
    }
  }
}
