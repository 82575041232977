/* Customize the label (the container) */
.container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	margin-top: -12px;
	cursor: pointer;
	// font-size: 24px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: black;
}

/* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: -2px;
	left: 0;
	transform: scale(0.6);
	height: 24px;
	width: 24px;
	border: 2px solid #c4c4c4;
	border-radius: var(--border-radius);
}

/* On mouse-over, add a grey background color */

.container:hover input ~ .checkmark {
	border-color: var(--primary-color);
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
	background-color: var(--primary-color);
	border-color: var(--primary-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
	left: 8px;
	top: 2px;
	width: 4px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
