.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.581);
	z-index: 1000;
	cursor: pointer;
}

.content {
	width: 30rem;
	position: fixed;
	top: 50%;
	left: 50%;
	// min-width: 70vw;
	background-color: white;
	z-index: 1200;
	// padding: 28px 32px;
	border-radius: var(--border-radius);
	// max-width: 25%;
	transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1300px) {
	.content {
		max-width: 60%;
	}
}

@media only screen and (max-width: 800px) {
	.content {
		max-width: 80%;
		min-width: 30rem;
	}
	.button-holder > button {
		font-size: 13px;

		&:nth-of-type(even) {
			margin-left: 5px;
		}
	}
}
