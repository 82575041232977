.user {
  .menu {
    display: flex;
    justify-content: space-around;
    & > * {
      margin: 10px;
    }
    &-holder {
      left: unset;
      right: -25%;
      transform: unset !important;
      padding-left: unset;
      padding-right: unset;
      cursor: default;
    }
    &-mod {
      flex-direction: column;
      color: black;
    }
    &-flex {
      display: flex;
      align-items: center;
    }

    &-name {
      color: black;
      white-space: nowrap;
      margin-right: 30px;
      margin-left: 10px;
      font-size: 24px;
    }
    &-button {
      background-color: white;
      border: none;
      color: var(--primary-color);
      font-size: 14px;
      font-family: inherit;
      cursor: pointer;
    }
    &-links {
      font-size: 16px;
      margin: unset;
      padding: 10px 0;
      // border-bottom: 0.5px solid #c4c4c4;
      & .menu-link {
        display: block;
        padding: 10px 15px;
        cursor: pointer;
        &:hover {
          background: #eaf8ff;
        }
      }
    }

    &-end {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: unset;
      & .menu-button {
        margin: 10px 0;
      }
      .pop {
        color: var(--primary-color);
        border-radius: var(--border-radius);
        width: fit-content;
        padding: 10px;
        margin: 10px auto;
        &:hover {
          color: white;
          background-color: var(--primary-color);
        }
      }
    }

    &-legal {
      display: flex;
      font-size: 13px;
      justify-content: center;
      padding: 10px 0;
      list-style: none;
      align-items: center;

      & li {
        color: #818181;
        &:first-child {
          margin-left: auto;
        }
        &:last-child {
          margin-right: auto;
        }
      }

      .dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: black;
        margin: 0 10px;
      }
    }
  }

  .options {
    position: relative;
    font-size: 20px;
    cursor: pointer;

    &:hover &-list {
      display: block;
      //   width: 100%;
    }
    &-list {
      position: absolute;
      top: 63px;
      background-color: white;
      // padding: 17px;
      border-radius: var(--border-radius);
      display: none;
      right: -140%;
      width: 260px;
      min-width: fit-content;
      box-shadow: 0px 5px 10px 0px #00000026;

      transform: translateX(-50%);
      &:hover {
        display: block;
      }
    }
    &-icon {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
    &::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
    }
  }
}
