@import '../../styling/mixins.scss';
.navbar {
	display: flex;
	justify-content: space-around;
	align-items: center;
	box-shadow: 0px 4px 10px 0px #00000026;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 300;
	& > * {
		padding: 15px 0 !important;
	}
}

.link_font {
	font-size: 17px;
}

.list {
	display: flex;
	list-style: none;
	margin-top: unset;
	margin-bottom: unset;
	justify-content: center;
	align-items: center;
	gap: 40px;

	& > a,
	& > li {
		cursor: pointer;
		&:hover {
			text-decoration: none;
		}
	}
}

a.list-active {
	color: var(--primary-color);
}

.logo {
	padding: 5px 0;

	height: 45px;
	cursor: pointer;
}

.holder {
	height: 100%;
	font-size: inherit;
	font-family: inherit;
	cursor: inherit;
}

.options {
	position: relative;
	font-size: 20px;
	cursor: pointer;
	&:hover &-list {
		display: block;
	}
	&-list {
		position: absolute;
		top: 63px;
		background-color: white;
		padding: 17px;
		border-radius: var(--border-radius);
		display: none;
		left: 50%;
		width: fit-content;
		box-shadow: 0px 5px 10px 0px #00000026;

		transform: translateX(-50%);
		&:hover {
			display: block;
			// width: 100%;
		}
	}
	&-icon {
		position: absolute;
		top: -10px;
		left: 50%;
		transform: translateX(-50%);
	}
	&::after {
		content: ' ';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 210%;
	}
}

.margLeft {
	color: #818181;
	margin-left: 20px;
	font-size: 14px;
	line-height: 21px;
	margin-top: 20px;
	& > p {
		margin: unset;
	}
}

.header {
	font-size: 18px;
	font-weight: 700;
	line-height: 21.6px;
	white-space: nowrap;
	& ~ * {
		white-space: nowrap;
		font-size: 16px;
	}

	&[data-em='false'] {
		content: '&nbsp;';
		height: 21.6px;
	}
}

.menu {
	display: flex;
	justify-content: space-around;
	color: black;
	& > * {
		margin: 20px;
	}
}

a {
	all: unset;
}

.auth {
	display: flex;
	width: 60vw !important;

	height: 80vh;
	& h5 {
		line-height: 1.2;
		font-weight: 600;
	}

	&-pic {
		width: 50%;
	}
	&-details {
		width: 55%;
	} 

	&-pic {
		background-image: linear-gradient(#1378a5d9, #1378a5d9), url('../../images/authBackground.webp');
		background-size: cover;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		background-color: var(--primary-color);
		display: flex;
		align-items: flex-end;
		justify-content: center;
		padding-bottom: 40px;
		font-size: 50px;
		font-weight: 700;
		line-height: 76.8px;
		text-align: center;
		color: white;
	}
	&-content {
		padding: 20px 40px;
		overflow-y: scroll;
		max-height: 80%;
		& {
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			scrollbar-width: none; /* Firefox */
		}
		&::-webkit-scrollbar {
			//display: block; /* Safari and Chrome */
			background-color: transparent;
		}
	}

	&-option {
		border-bottom: 1px solid var(--primary-color);
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px 20px 15px 40px;

		.options_element {
			display: flex;
			align-items: center;
		}
	}

	@media (max-width: 900px) {
		display: flex;
		flex-direction: column;
		width: 100% !important;
		height: 100vh;
		overflow-y: scroll;
		margin: 0;
		padding: 0;

		&-option {
			width: 100% !important;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 10px 7.5px 20px;
		}

		&-pic {
			width: 100%;
			white-space: initial;
			background-size: cover;
			font-size: 40px;
			height: fit-content;
			justify-content: center;
			font-size: 30px;
			line-height: 40px;
		}
		&-details {
			width: 100%;
		}

		&-content {
			width: 100%;
		}
	}
}

.logged {
	justify-content: space-between;
	& > * {
		margin-left: 10px;
		margin-right: 10px;
	}
	svg:first-child:hover > path {
		fill: var(--primary-color);
	}
}

.avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #9fbc0b;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
}

.subsmall {
	max-width: 40vw;
	@include flex(column);
	padding: 20px;
	gap: 20px;
	& > * {
		margin: unset;
	}

	p {
		font-weight: 700;
	}
}

.mob_nav_element {
	display: none;
}
@media print {
	.navbar {
		display: none;
	}
}

@media (max-width: 900px) {
	.list {
		display: none;
	}

	.navbar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0px 4px 10px 0px #00000026;
		position: sticky;
		top: 0;
		background-color: white;
		z-index: 100;
		& > * {
			padding: 10px 15px;
		}
	}

	.mob_nav_element {
		display: inline;
		&:hover {
			cursor: pointer;
		}
	}
}

.dropdownnav {
	display: none;
}

.navtoggle {
	display: none;
}

@media (max-width: 900px) {
	.navtoggle {
		display: none;
	}

	.dropdownnav {
		z-index: 1000;
		display: flex;
		background: white;
		width: 100vw;
		position: fixed;
		flex-direction: column;
		text-decoration: none;

		height: 100vh;

		.dropdownnav_list {
			display: flex;
			flex-direction: column;

			.nav_link {
				display: flex;
				align-items: center;
				font-size: 20px;
				padding: 10px 30px 10px 20px;

				path {
					fill: var(--primary-color);
				}

				.nav_icon {
					margin: 0 20px 0 0;
				}

				p {
					margin: 0;
					white-space: nowrap;
				}

				&:hover {
					cursor: pointer;
					background-color: #ffffff70;
				}
			}
		}

		// .dropdownnav_item {
		//   display: flex;
		//   align-items: center;
		//   justify-content: space-between;
		//   padding: 0 20px;
		//   font-size: 20px;
		// }
	}
}
