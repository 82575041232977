.left_navbar {
	background-image: url(../../assets/navbar_bg.webp);
	background-repeat: no-repeat;
	background-size: cover;
	left: 0;
	width: 22.7%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	overflow-y: auto;
	z-index: 500;
	padding-bottom: 2rem;

	.active {
		svg > path {
			color: unset;
		}
	}

	h1 {
		color: white;
		font-weight: 400;
		font-size: 30px;
	}

	.nav_links_wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.nav_link {
		display: flex;
		align-items: center;
		font-size: 16px;
		color: #ffffff;
		padding: 15px 30px 15px 50px;

		path {
			fill: white;
		}

		.nav_icon {
			margin: 0 20px 0 0;
			font-size: 1.5rem;
		}

		p {
			margin: 0;
			white-space: nowrap;
		}

		&:hover {
			cursor: pointer;
			background-color: #ffffff70;
		}
	}

	.nav_links_wrapper a.active {
		background: #ffffff;
		color: #1378a5;
		font-weight: 400;
	}

	.nav_links_wrapper a.active svg path {
		fill: #1378a5;
	}

	.hr {
		padding: 18px 0;
	}

	hr {
		width: 80%;
		margin: 0 auto;
		border: 1px solid #ffffff80;
	}
}

.drop_icon {
	margin-left: auto;
	color: black;
}

.content_wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 50vh;
	border-radius: 5px;

	.modal_info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
		background: #1378a5bf;

		p:nth-of-type(1) {
			font-weight: 500;
			font-size: 40px;
			line-height: 50px;
			text-align: center;
			color: #ffffff;
			width: 70%;
		}

		p:nth-of-type(2) {
			font-style: normal;
			font-weight: 400;
			font-size: 24px;
			line-height: 30px;
			text-align: center;
			color: #ffffff;
			width: 72%;
		}

		span {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 0px;
			text-align: center;
			color: #ffffff;
		}
	}

	.modal_button {
		background: #ffffff;
		opacity: 100%;
		display: flex;
		flex-direction: column;

		p {
			text-align: center;
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 29px;
			text-align: center;
			color: #1378a5;
			padding: 0 20px;

			&:hover {
				cursor: pointer;
			}
		}
	}
}

@media print {
	.left_navbar {
		display: none;
	}
}

// @media only screen and (max-width: 1200px) {
// 	.left_navbar {
// 		.nav_link {
// 			padding: 15px;
// 		}
// 	}
// }

@media only screen and (max-width: 1200px) {
	.left_navbar {
		width: max(30%, 300px);
		box-shadow: 9px 7px 8px 0px rgb(0 0 0 / 15%);
	}
}
